/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS611D
 * 화면 설명: 청약 파트 전자서명 신계약체결 완료
              AS-IS TSSPS181D 전환 화면

 */
<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
      <ur-box-container alignV="start" componentid="" direction="column">
        
        <template v-if="step === '1'">
          <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="pal1624">
            <span class="fs22rem fwb">전자서명</span>
            <div>
              <span class="fs20rem crTy-blue2 fwb mr2">1</span>/
              <span class="ml2" v-if="itemData.eltrnSaveData.frtmPrmPadMthCd === '4'">2</span>
              <span class="ml2" v-else>3</span>
            </div>
          </ur-box-container>
          <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="bd-T-Ty1 h10px bgcolor-3">
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="pt30 pl24 pr24 pb10">
            <div class="fs22rem fwm ">{{planNm}}</div>
            <div class="ns-check mt4">
              <mo-checkbox class="is-checked" readonly>전자서명이 완료되었습니다</mo-checkbox>
            </div>
            <!-- <ul class="bullet-type-3 mt30 crTy-bk7">
              <li>전자서명 계약서류는 당사 홈페이지에서도 확인하실 수 있습니다.</li>
              <li>계약서류의 서면 교부 요청 시 보험계약자에게 우편발송 또는 직접 제공해드립니다.</li>
            </ul> -->
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="con-area bd-T-Ty1 bd-b-Ty1 bgcolor-3 pt20 pb20">
            <div class="fs18rem fwm">청약<span class="crTy-blue3">접수</span>를 진행하여<br />신계약체결을 완료하세요.</div>
            <span class="label-title mt10">서비스콜 통화시간(선택)</span>  <!-- 레이블 -->
            <msp-bottom-select ref="bottomSelect" :itemValue="'data'" :itemText="'label'" v-model="select1.value.data" :items="select1.items" underline class="ns-dropdown-sheet" placeholder="선택하세요" bottom-title="서비스콜 통화시간 선택" closeBtn/>
            <!--ASR230900888 서비스콜 어종 항목 추가
             외국어 모바일 보험가입확인 가능상품인 경우에만 서비스콜 어종이 노출 가능해야함 -->
            <span v-show="isForeigner" class="label-title mt10">서비스콜 외국어(선택)</span>  <!-- 레이블 -->
            <msp-bottom-select v-show="isForeigner" v-if="languageCd === '01'" ref="bottomSelect" :itemValue="'data'" :itemText="'label'" v-model="select2.value.data" :items="select2.items" underline class="ns-dropdown-sheet" placeholder="선택" bottom-title="서비스콜 외국어 선택" closeBtn/>
            <msp-bottom-select v-show="isForeigner" v-if="languageCd === '03'" ref="bottomSelect" :itemValue="'data'" :itemText="'label'" v-model="select2.value.data" :items="select2.items2" underline class="ns-dropdown-sheet" placeholder="선택" bottom-title="서비스콜 외국어 선택" closeBtn/>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt30 mb100">
            <ul class="bullet-type-3 crTy-bk7">
              <li>전자서명 계약서류는 당사 홈페이지에서도 확인하실 수 있습니다.</li>
              <li>계약서류의 서면 교부 요청 시 보험계약자에게 우편발송 또는 직접 제공해드립니다.</li>
            </ul>
          </ur-box-container>
        </template>

        <template v-if="step === '2'">
          <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="pal1624">
            <span class="fs22rem fwb">청약접수</span>
            <div>
              <span class="fs20rem crTy-blue2 fwb mr2">2</span>/
              <span class="ml2" v-if="itemData.eltrnSaveData.frtmPrmPadMthCd === '4'">2</span>
              <span class="ml2" v-else>3</span>
            </div>
          </ur-box-container>
          <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="bd-T-Ty1 h10px bgcolor-3">
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="pt30 pl24 pr24 pb10">
            <div class="fs22rem fwm ">{{planNm}}</div>
            <div class="ns-check mt4">
              <mo-checkbox class="is-checked" readonly>청약접수가 완료되었습니다</mo-checkbox>
            </div>
            <div class="text-blue-box crTy-bk7 mt20">삼성생명을 믿고 선택해 주신 고객님께 다시 한번 감사드리며, 고객님께 최상의 서비스를 제공할 수 있도록 항상 노력하겠습니다. </div>
            <div class="mt20 fs18rem fwm"><span class="crTy-blue3">보험료이체</span>를 진행하여<br />신계약체결을 완료하세요.</div>
            <ul class="bullet-type-3 mt30 crTy-bk7">
              <li>전자서명 계약서류는 당사 홈페이지에서도 확인하실 수 있습니다.</li>
              <li>계약서류의 서면 교부 요청 시 보험계약자에게 우편발송 또는 직접 제공해드립니다.</li>
            </ul>
          </ur-box-container>
        </template>

        <template v-if="step === '3'">
          <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="pal1624">
            <span class="fs22rem fwb">이체</span>
            <div><span class="fs20rem crTy-blue2 fwb mr2">3</span>/<span class="ml2">3</span></div>
          </ur-box-container>
          <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="bd-T-Ty1 h10px bgcolor-3">
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="pt30 pl24 pr24 pb10">
            <div class="w100 fs22rem fwm txt-center">제<span>1</span>회 보험료<br />이체 신청이 완료되었습니다.</div>
            <div class="text-blue-box crTy-bk7 mt20">삼성생명을 믿고 선택해 주신 고객님께 다시 한번 감사드리며, 고객님께 최상의 서비스를 제공할 수 있도록 항상 노력하겠습니다. </div>
            <ul class="bullet-type-3 mt30 crTy-bk7">
              <li>전자서명 계약서류는 당사 홈페이지에서도 확인하실 수 있습니다.</li>
              <li>계약서류의 서면 교부 요청 시 보험계약자에게 우편발송 또는 직접 제공해드립니다.</li>
            </ul>
          </ur-box-container>
        </template>

        <template v-if="step === '4'">
          <ur-box-container alignV="start" componentid="" direction="column" class="pt30 pl24 pr24 pb10">
            <div class="fs22rem fwm ">{{planNm}}</div>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="pt30 pl24 pr24 pb10">
            <div class="w100 fs22rem fwm txt-center">신계약체결이 완료되었습니다.</div>
            <div class="text-blue-box crTy-bk7 mt20">삼성생명을 믿고 선택해 주신 고객님께 다시 한번 감사드리며, 고객님께 최상의 서비스를 제공할 수 있도록 항상 노력하겠습니다. </div>
            <ul class="bullet-type-3 mt30 crTy-bk7">
              <li>전자서명 계약서류는 당사 홈페이지에서도 확인하실 수 있습니다.</li>
              <li>계약서류의 서면 교부 요청 시 보험계약자에게 우편발송 또는 직접 제공해드립니다.</li>
            </ul>
          </ur-box-container>
        </template>

      </ur-box-container>
    </ur-box-container>
    <!-- Content 영역 end -->
    <template>
      <ur-box-container v-if="step === '1'" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round white" @click="fn_MovePage">나중에하기</mo-button>
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ServiceDataOfr">청약 접수</mo-button>
        </div>
      </ur-box-container>
      <ur-box-container v-if="step === '2'" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <ul class="bullet-type-4 crTy-bk7 pl8 mb11">
            <li class="fs16rem">초회보험료 <span>{{$bizUtil.numberWithCommas(crncyCd === 'KRW'? contPrmAmt : contPrmAmtKrw)}}</span>원</li>
          </ul>
        </div>
        <div class="relative-div">
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round white" @click="fn_MovePage">나중에하기</mo-button>
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" :disabled="untyMsgCntnt !== ' '" @click="fn_ServiceDataDfra()">이체</mo-button>
        </div>
      </ur-box-container>
      <ur-box-container v-if="step === '3'" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ServiceData('S13')">이체결과 확인</mo-button>
        </div>
      </ur-box-container>
      <ur-box-container v-if="step === '4'" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative noshadow">  
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_MovePage">완료</mo-button>
        </div>
      </ur-box-container>
    </template>

  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import moment from 'moment'
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import MSPPS611P from '@/ui/ps/MSPPS611P' // 청약접수팝업 AS-IS TSSPS184P
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS611D', 
  screenId: 'MSPPS611D', 
  components: {
    'MSPPS611P' : MSPPS611P //AS-IS 청약접수팝업 TSSPS184P
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    planNm: String,
    currentStep: String,
    itemData: {type: Object}
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  /*
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  */

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      lv_AlertPop : null,
      initSearchFlag: false,
      contPrmAmt: '0',
      step: ' ',
      untyMsgCntnt: ' ',
      addContBankInfo: {},
      eltrnSaveData: {},
      //isTSSPS184P: false,
      popMSPPS611P : null,
      //ASR230900888 서비스콜 어종 항목 추가
      isForeigner: false,
      languageCd: '',
      select1: {
        value: {key: '', label: '', data: ' '},
        items: [
          {key: '10', label: '09:00 ~ 10:00', data: '09,10'},
          {key: '11', label: '10:00 ~ 11:00', data: '10,11'},
          {key: '12', label: '11:00 ~ 12:00', data: '11,12'},
          {key: '13', label: '12:00 ~ 13:00', data: '12,13'},
          {key: '14', label: '13:00 ~ 14:00', data: '13,14'},
          {key: '15', label: '14:00 ~ 15:00', data: '14,15'},
          {key: '16', label: '15:00 ~ 16:00', data: '15,16'},
          {key: '17', label: '16:00 ~ 17:00', data: '16,17'},
          {key: '18', label: '17:00 ~ 18:00', data: '17,18'},
          {key: '19', label: '18:00 ~ 19:00', data: '18,19'},
          {key: '20', label: '19:00 ~ 20:00', data: '19,20'}
        ],
        itemData611P: {} // 611P 가공 데이터
      },
      // ASR230900888 서비스콜 어종 항목 추가
      select2: {
        value: {key: '', label: '', data: ' '},
        items: [
          {key: '01', label: '러시아어', data: '01'},
          {key: '03', label: '중국어', data: '03'}
        ],
        items2: [
          {key: '03', label: '중국어', data: '03'}
        ]
      },
      msgDesc: '',
      //completeType: '',
      //negativeButtonLabel: '',
      //positiveButtonLabel: '',
      //isPrgPhsePopup: false,
      bnkCd: '',
      bnkAccnno: '',
      dpstrnm: '',
      dpstrRrn: '',
      dfraCnsntDtm: '',
      serverChoice: '',
      contPrmAmtKrw: '0', // 202008 외화보험 초회보험료 KRW
      exrtRate: '', // 202007 외화보험 환율
      cnvlStndYmd: '', // 202007 외화보험 환율 조회용 계약일자
      crncyCd: '', // 202007 외화보험 통화구분
      contNo: '' // 202009 외화보험 계약번호
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle 인스턴스가 Active (활성화) 된 후 호출
  activated () {
    this.fn_ActivatedLoading()
  },

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    // backButton event 등록
    //window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

    this.serverChoice = process.env.NODE_ENV
    this.itemData611P = {}

  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
    return
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
     // backButton event 해제
    //window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    
  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/

  /******************************************************************************
  * Function명  : fn_DevicBackBtnHandler
  * 설명        : 디바이스 Back Button 핸들러
  ******************************************************************************/
  fn_DevicBackBtnHandler() {
    return
  },

  /******************************************************************************
  * Function명 : fn_SearchEvent
  * 설명       : 초기 로딩
  ******************************************************************************/
  fn_SearchEvent () {
    if (this.currentStep === '1') { // 청약접수
      if (!this.initSearchFlag) {
        this.fn_Trace('info', '[접수] 시작')
        this.initSearchFlag = true
        this.fn_ForeignerCheck() // 외국인 체크
        this.fn_ServiceData('S1') // 전자서명 세부 정보조회
        //화면 진입시 3종 전문호출(702, ICPS, 실손전문) 
        console.log('========화면 진입시 전문 호출=========')
        this.fn_ServiceData('P16')
        this.step = '1'
      } // end if
    } else if (this.currentStep === '2') { // 현장출금
      this.fn_Trace('info', '[현장출금테스트] 시작')
      this.contPrmAmt = this.itemData.eltrnSaveData.mpftp // 초회보험료 이체
      this.fn_ServiceData('S1') // 전자서명 세부 정보조회
      this.step = '2'
    } else if (this.currentStep === '3') { // 출금 확인
      this.step = '3'
    } else if (this.currentStep === '4') { // 완료
      this.step = '4'
    } // end else if
  },

  /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/
  /******************************************************************************
  * Function명 : fn_ForeignerCheck
  * 설명       : 외국인 체크
  ******************************************************************************/
  
  fn_ForeignerCheck: function () {

    this.fn_Trace('info', '[서비스콜] 외국인 여부')
    console.log(this.itemData.gafData.hoshInfoList)
    let d = this.itemData.gafData
    let hl = d.hoshInfoList || []
    let rrn1 = '' // 계약자 주민번호
    let zzcusDtlTypCd = ''
    let prcd = this.itemData.eltrnSaveData.prcd.substr(0,6)

    this.isForeigner = false
    this.languageCd = ''
    console.log('zzzzzzzzzzz  prcd' + prcd)

    for (let i = 0; i < hl.length; i++) {
      if (hl[i].contvInsrdCd === '11') rrn1 = hl[i].custRrnId
      if (hl[i].contvInsrdCd === '11') zzcusDtlTypCd = hl[i].cusDtlTypCd
    }
    console.log('zzzzzzzzzzz' + rrn1.substr(6,1))
    console.log('zzzzzzzzzzz' + rrn1.length)
    console.log('zzcusDtlTypCd' + zzcusDtlTypCd)
    if(rrn1.length == 13){
      if(rrn1.substr(6,1) === "5" || rrn1.substr(6,1) === "6" || rrn1.substr(6,1) === "7" || rrn1.substr(6,1) === "8"){

        if(prcd ==="LP0582" || prcd ==="LP0581" || prcd ==="LP0126" || prcd ==="LP0390"){
          console.log('서비스콜 외국어 미노출 상품')
        }else{
          if(zzcusDtlTypCd !== "21" && zzcusDtlTypCd !== "22" && zzcusDtlTypCd !== "23"){
            this.isForeigner = true
            this.languageCd = '03'
          }
          if(prcd ==="LP0397" || prcd ==="LP0554" || prcd ==="LP0639" || prcd ==="LP0640" || prcd ==="LP0641" || prcd ==="LP0643" || prcd ==="LP0816" || prcd ==="LP0817"){
            this.isForeigner = true
            this.languageCd = '01'
          }

        }
      }
    }
    console.log('zzzzzzzzzzz isForeigner' + this.isForeigner)
    console.log('zzzzzzzzzzz languageCd' + this.languageCd)
  },

  /******************************************************************************
  * Function명 : fn_ServiceDataOfr
  * 설명       : 현장접수 I/F 실행
  ******************************************************************************/
  
  fn_ServiceDataOfr: function () {
    // 지급전문 2종 호출 서비스 호출만 하기 
    //this.fn_ServiceData('P16')
    // 현장접수 시작
    let frtmPrmPadMthCd = this.itemData.eltrnSaveData.frtmPrmPadMthCd
    let dfraCnsntDtm = this.itemData.eltrnSaveData.dfraCnsntDtm
    if (frtmPrmPadMthCd === '4' || (frtmPrmPadMthCd === '1' && (Number(dfraCnsntDtm) >= 233000 || Number(dfraCnsntDtm) < 73000))) {
      let cmcustAccnInfoListVO = this.itemData.bankData.cmcustAccnInfoListVO
      for (let i = 0; i < cmcustAccnInfoListVO.length; i++) {
        let cmcustAccnInfo = cmcustAccnInfoListVO[i]
        if (cmcustAccnInfo.accnId === this.itemData.eltrnSaveData.bnkAccnId) {
          this.bnkCd = cmcustAccnInfo.bnkCd // 은행코드
          this.bnkNm = cmcustAccnInfo.bnkCdNm // 금융기관명
          this.bnkAccnno = cmcustAccnInfo.accnNo // 계좌번호
          this.dpstrnm = cmcustAccnInfo.dpstrNm // 예금주명
          this.dpstrRrn = this.itemData.bankData.rrn.substring(0, 6) // 예금주주민번호
        }
      }
      this.fn_ServiceData('S4') // 계좌검증
    } else {
      this.fn_ServiceData('U2')
    }
  },
  
  /******************************************************************************
  * Function명 : fn_ServiceDataDfra
  * 설명       : 계좌정보 추출
  ******************************************************************************/
  fn_ServiceDataDfra () {
    let lv_Vm = this
    // 오늘 날짜 조회
    this.$commonUtil.getServerTime()
    .then(function (response) {
      let chkTm = moment(response).format('HH')
      // 20시 이후 출금 중지 처리
      // 2020.07.21 ASR200700506 전자서명 현장접수시간 확대 ( 20시 → 23시)
      if ( Number(chkTm) >= 23 ) {
        lv_Vm.fn_BottomAlert('현장출금접수 가능 시간이 아닙니다.')
      } else {
        lv_Vm.addContBankInfo = {}

        if(lv_Vm.eltrnSaveData.frtmPrmPadMthCd !== '9'){
          let cmcustAccnInfoListVO = lv_Vm.itemData.bankData.cmcustAccnInfoListVO
          for (let i = 0; i < cmcustAccnInfoListVO.length; i++) {
            let cmcustAccnInfo = cmcustAccnInfoListVO[i]
            if (cmcustAccnInfo.accnId === lv_Vm.eltrnSaveData.bnkAccnId) {
              lv_Vm.addContBankInfo.bnkCd = cmcustAccnInfo.bnkCd // 은행코드
              lv_Vm.addContBankInfo.bnkNm = cmcustAccnInfo.bnkCdNm // 금융기관명
              lv_Vm.addContBankInfo.bnkAccnno = cmcustAccnInfo.accnNo // 계좌번호
              lv_Vm.addContBankInfo.dpstrnm = cmcustAccnInfo.dpstrNm // 예금주명
            } // end if
          } // end for i
        }
        // lv_Vm.addContBankInfo.elstPartnNo = lv_Vm.eltrnSaveData.elstPartnNo // 파트너 번호
        // 2020.03.02 : 예금주 BPID 변경
        lv_Vm.addContBankInfo.elstPartnNo = lv_Vm.eltrnSaveData.elstDpstrPartnNo // 예금주 파트너 번호
        lv_Vm.fn_ServiceData('U8')
      }
    })
  },

  /******************************************************************************
  * Function명 : fn_ServiceData
  * 설명       : 서비스 조회 함수
  ******************************************************************************/
  fn_ServiceData (serviceName) {
    let pParams = {}
    switch (serviceName) {
      case 'S1': // 전자서명 세부 정보조회
      case 'S1_1': // 전자서명 세부 정보조회
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99S14')) //AML대응 txTSSPS99S1 => txTSSPS99S14
        pParams.data = {}
        pParams.data.vuchId = this.itemData['vuchId'] // 전자문서ID
        break
      case 'U2': // 현장접수신청일자
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS98U4'))
        pParams.data = {}
        pParams.data.vuchId = this.itemData['vuchId'] // 전자문서ID
        break
      case 'S3': // 수익자 인증 기록 조회 - TTSD00003 Table
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS97S1'))
        pParams.data = {}
        pParams.data.vuchId = this.itemData['vuchId'] // 영수증 번호
        break
      case 'U4': // 서비스 콜 입력
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99U9'))
        pParams.data = {}
        pParams.data.vuchId = this.itemData['vuchId'] // 영수증 번호
        pParams.data.srvclPhclTmCntnt = this.select1.value['data']
        // ASR230900888 서비스콜 어종 항목 추가
        pParams.data.srvclFrlanCd = this.select2.value['data']
        break
      case 'U5_1': // 계약자 기준정보 업데이트
      case 'U5_2': // 피보험자 기준정보 업데이트
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13U2'))
        pParams.data = {}
        pParams.data.elstPlanId = this.itemData['planId'] // 설계ID 
        pParams.data.vuchId = this.itemData['vuchId'] // 영수증 번호

        if (serviceName === 'U5_1') { // 계약자
          pParams.data.custContRltnCd   = '11' //'21' 되어 있던 값 11로 수정 서비스 처리로직에선 참고 하지 않음
          pParams.data.elstPartnNo      = this.eltrnSaveData.elstPartnNo // 전자서명파트너번호
          pParams.data.mobslChnlCustId  = this.eltrnSaveData.mobslChnlCustId // 모바일영업채널고객ID
          pParams.data.mobslChnlCstgrId = this.eltrnSaveData.mobslChnlCstgrId // 모바일영업채널고객그룹ID
          pParams.data.mnContrNm        = this.eltrnSaveData.mnContrNm // 주계약자명

          pParams.data.smsReciCnsntYn   = this.eltrnSaveData.contrSmsReciCnsntYn // 계약자SMS수신동의여부
          pParams.data.smartReciCnsntYn = this.eltrnSaveData.contrSmartReciCnsntYn // 계약자스마트수신동의여부
          pParams.data.dwScCd           = this.eltrnSaveData.contrDwScCd // 계약자거주구분코드
          pParams.data.eltrnMalReciYn   = this.eltrnSaveData.contrEltrnMalReciYn // 계약자전자메일수신여부
          pParams.data.mailRcpplCd      = this.eltrnSaveData.mailRcpplCd // 우편물수령처코드
          pParams.data.secusCvancMthCd  = this.eltrnSaveData.secusCvancMthCd // 증권전달방법코드

        } else if (serviceName === 'U5_2') { // 피보험자
          pParams.data.custContRltnCd   = '21' //'11' 되어 있던 값 21로 수정 서비스 처리로직에선 참고 하지 않음
          pParams.data.elstPartnNo      = this.eltrnSaveData.elstInsrdPartnNo // 전자서명파트너번호
          pParams.data.mobslChnlCustId  = this.eltrnSaveData.insrdChnlCustId // 모바일영업채널고객ID
          pParams.data.mobslChnlCstgrId = this.eltrnSaveData.mobslChnlCstgrId // 모바일영업채널고객그룹ID
          pParams.data.mnContrNm        = this.eltrnSaveData.insrdNm // 주계약자명

          pParams.data.smsReciCnsntYn   = this.eltrnSaveData.insrdSmsReciCnsntYn // 피보험자SMS수신동의여부
          pParams.data.smartReciCnsntYn = this.eltrnSaveData.insrdSmartReciCnsntYn // 피보험자스마트수신동의여부
          pParams.data.dwScCd           = this.eltrnSaveData.insrdDwScCd // 계약자거주구분코드
          pParams.data.eltrnMalReciYn   = this.eltrnSaveData.insrdEltrnMalReciYn // 계약자전자메일수신여부
          pParams.data.mailRcpplCd      = this.eltrnSaveData.insrdMailRcpplCd // 우편물수령처코드
        } 
        break
      case 'U5_3': // 법인 실제소유자 정보 등록
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13U3'))
        pParams.data = {}
        pParams.data.vuchId = this.itemData['vuchId'] // 영수증ID
        pParams.data.partner = this.eltrnSaveData.elstPartnNo // 단체고객ID
        pParams.data.userid = PSServiceManager.getUserInfo('userEno') // 처리자
        break
      case 'P5': // 청약입력/접수
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS18P3'))
        pParams.data = {}
        pParams.data.elstPlanId = this.itemData['planId'] // 설계ID
        pParams.data.vuchId = this.itemData['vuchId'] // 영수증 번호
        break
      case 'U6': // 청약입력/접수 결과 저장
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99U6'))
        pParams.data = {}
        pParams.data.vuchId = this.itemData['vuchId'] // 영수증 번호
        pParams.data.contNo = this.eltrnSaveData.contNo // 접수번호(계약번호)
        pParams.data.ofrAcpnrEno = this.eltrnSaveData.ofrAcpnrEno // 청약접수자사번
        pParams.data.mpftp = this.eltrnSaveData.mpftp // 실납임보험료
        pParams.data.crncyCd = this.crncyCd // 202005 외화보험 통화키
        this.fn_Trace('info', '청약입력/접수 결과 저장', pParams.data)
        break
      case 'U7': // 현장접수완료일자수정
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS98U7'))
        pParams.data = {}
        pParams.data.vuchId = this.itemData['vuchId'] // 영수증 번호
        pParams.data.elstPrgStatCd = '30' // 10:서명중/20:서명완료/30:접수완료/40:이체완료
        pParams.data.scnAcpnCmpltYn = 'Y' // 현장접수완료 여부
        break
      case 'U8': // 초회보험료이체일자수정
      case 'U8_dis': // 초회보험료이체 실패
      case 'U8_end': // 가상계좌 와 후입금 일때 이체 불가 또는 처리된 접수 일 때 99999999 입력처리 결과
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS98U8'))
        pParams.data = {}
        if (serviceName !== 'U8') {
          pParams.data.updFrtmPrmTrsfYmd = '99999999'
        } // else if
        pParams.data.vuchId = this.itemData['vuchId'] // 전자문서ID
        break
      case 'P9': // 출금동의 요청
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS18P1'))
        pParams.data = {}
        pParams.data.bukrs = 'I100' // 회사 코드
        pParams.data.zaDfraCnsntAplYmd = PSDateUtil.fn_CurrentDate() // 출금동의신청일자
        pParams.data.zaDlScVal = '1' // 등록(1)과 해지(2) 우리는 1만 사용함
        pParams.data.partner = this.addContBankInfo.elstPartnNo // 파트너 번호
        pParams.data.zaBnkCd = this.addContBankInfo.bnkCd // 은행코드
        pParams.data.zaAccnNo = this.addContBankInfo.bnkAccnno // 계좌번호
        pParams.data.zaDpstPesonNo = this.itemData.bankData.rrn // 주민/사업자번호
        pParams.data.zaPayrNo = this.addContBankInfo.elstPartnNo // 납부자번호 (파트너 번호)
        pParams.data.zaDfraCnsntAplrId = this.eltrnSaveData.ofrAcpnrEno // 출금동의신청자ID
        // 2022.02.08 ASR211101024_[사랑On] 신계약 자동납부동의자료 전송 가이드라인에 따른 코드값 변경 요청 (01 --> 03)
        pParams.data.zaDfraCnsntMthCd = '03' // 출금동의방법코드 00 : 증빙없음, 01 : 서면, 02 : 공인인증, 03 : 일반인증 (PQM과 동일하게 사용)
        pParams.data.zaHstrOccuRsnCd = '' // 이력발생사유코드 (동의요청 시 미사용) - 01 생성, 02 재시도, 03 취소, 04 삭제
        break
      case 'S10': // 출금동의 등록 조회
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS18S1'))
        pParams.data = {}
        pParams.data.bukrs = 'I100' // 회사 코드
        pParams.data.zaPayrNo = this.addContBankInfo.elstPartnNo // 납부자번호 (파트너 번호)
        pParams.data.zaBnkCd = this.addContBankInfo.bnkCd // 은행코드
        pParams.data.zaAccnNo = this.addContBankInfo.bnkAccnno // 계좌번호
        break
      case 'P11': // RTP 처리
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS18P2'))
        pParams.data = {}
        pParams.data.bukrs = 'I100' // 회사 코드
        pParams.data.waers = 'KRW' // 통화
        pParams.data.applnrTt = this.eltrnSaveData.contNo // 계약번호

        // TC 정산금액 외화보험 USD > KRW로 수정
        if (!this.$bizUtil.isEmpty(this.crncyCd) && this.crncyCd === 'USD') {
          pParams.data.augbw = this.contPrmAmtKrw
        } else {
          pParams.data.augbw = this.eltrnSaveData.mpftp // TC 정산 금액
        }

        // pParams.data.pymet = '1' // 납부 방법 RT= 1, 카드 = 9
        pParams.data.pymet = this.eltrnSaveData.frtmPrmPadMthCd // 납부 방법 RT= 1, 카드 = 9
        if(this.eltrnSaveData.frtmPrmPadMthCd === '1'){
          pParams.data.bkvid = this.eltrnSaveData.bnkAccnId // 은행 계정 정보
        }else if(this.eltrnSaveData.frtmPrmPadMthCd === '9'){
          pParams.data.ccardId = this.eltrnSaveData.crmtBuagCcardOut // 지급카드 ID = 카드 계정 정보(신규필드)
          pParams.data.ccname = this.eltrnSaveData.mnContrNm // 카드소유주 = 주계약자명
        }
        pParams.data.gpart = this.addContBankInfo.elstPartnNo // 비즈니스 파트너
        pParams.data.zaBnkbOtln = '삼성생명계약;신계약보험료' // 통장적요
        pParams.data.zaUserSnoId = this.eltrnSaveData.ofrAcpnrEno // 사용자일련번호ID
        pParams.data.zaPesnDeptCd = PSServiceManager.getUserInfo('userDeptCd') // 인사부서코드
        break
      case 'U12': // 초회보험료지급ID업데이트
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99U10'))
        pParams.data = {}
        pParams.data.vuchId = this.itemData['vuchId'] // 전자문서ID
        pParams.data.frtmPrmPymId = this.addContBankInfo.payid // 지급ID
        break
      case 'S13': // RTP 처리 결과 조회
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS18S2'))
        pParams.data = {}
        pParams.data.bukrs = 'I100' // 회사 코드
        if (this.eltrnSaveData.frtmPrmPymId !== ' ') {
          pParams.data.payid = this.eltrnSaveData.frtmPrmPymId // 지급ID
        } else {
          pParams.data.payid = this.addContBankInfo.payid // 지급ID
        } // end else if
        break
      case 'U14': // 초회보험료이체완료
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS98U11'))
        pParams.data = {}
        pParams.data.vuchId = this.itemData['vuchId'] // 전자문서ID
        pParams.data.elstPrgStatCd = '40' // 10:서명중/20:서명완료/30:접수완료/40:이체완료
        pParams.data.frtmPrmCmpltYn = 'Y' // 이체완료 여부
        pParams.data.fofDfraYn = 'N' // 이체완료 여부
        break
      case 'P15': // 이미지스캔
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS18P6'))
        pParams.data = {}
        pParams.data.moblBusnScCd = 'SE' // 모바일업무구분코드
        pParams.data.eltrnDoctScCd = '01' // 전자문서구분코드
        pParams.data.eltrnDoctId = this.itemData['vuchId'] // 전자문서ID
        pParams.data.eltrnDoctFilePathNm = 'file.path.ofr.as' // this.itemData.baseData.tbtPbFilePath // 전자문서파일경로명
        pParams.data.regntEno = this.eltrnSaveData.ofrAcpnrEno // 등록자사번
        pParams.data.reqShpScCd = '02' // 요청형태구분코드 02:스캔
        pParams.data.reqSrvcNm = 'TSSPS180D' // 요청서비스명
        pParams.data.contNo = this.eltrnSaveData.contNo // 계약번호
        pParams.data.rrno = this.itemData.bankData.rrn // 관련자주민등록번호
        break
      case 'aeus': // AEUS 대상 여부 조회
      case 'aeus_1': // AEUS 대상 여부 조회
        // let sdrvDtVal = JSON.stringify({ZA_PLAN_ID: this.itemData['planId']})
        let sdrvDtVal = JSON.stringify({ZA_PLAN_ID: this.itemData['planId'], ZA_VUCH_NO: this.itemData['vuchId']})
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS10S8'))
        pParams.data = {}
        pParams.data.cmndSrvcNm = 'selEPD28'
        pParams.data.sdrvDtVal = sdrvDtVal
        break
      case 'P16': // 지급전문 2종 호출
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS18P5'))
        pParams.data = {}
        pParams.data.zCrdinInfoMobIfDto = {}
        pParams.data.zCrdinInfoMobIfDto.zaPlanId = this.itemData['vuchId'] // 영수증번호
        pParams.data.zCrdinInfoMobIfDto.zaClctCnsltNo = PSServiceManager.getUserInfo('userEno') // 모집컨설턴트 번호
        break
      case 'S4': // 2020.06.23 ASR200501008_전자서명 완료시간 확대 (계좌검증 추가)
        pParams.appId = 'sli.eai'
        pParams.trnstId = 'txTSSPS13K1'
        pParams.fnctScCd = 'K'
        pParams.serviceId = 'C339_F1CDE0001_S'
        pParams.reqVONm = 'sli.in.ea2.vo.a0001cd.EAF1CDE0001ReqVO'
        pParams.resVONm = 'sli.in.ea2.vo.a0001cd.EAF1CDE0001ResVO'
        pParams.data = {}
        pParams.data.BUKRS = 'I100'
        pParams.data.ZA_TLGM_DTL_DMN_BNK_CD = this.bnkCd
        pParams.data.ZA_TLGM_RECI_ACCN_NO = this.bnkAccnno
        pParams.data.ZA_DPST_PESON_NM = this.dpstrnm
        pParams.data.ZA_DPST_PESON_NO = this.dpstrRrn
        break
      case 'S5': // 환율조회 202008 외화보험
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS18S9'))
        pParams.data = {}
        pParams.cnvlStndYmd = this.cnvlStndYmd
        break
      case 'S6': // FS-PM 초회보험료 조회 202009 외화보험
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS18S10'))
        pParams.data = {}
        pParams.data.contNo = this.contNo
        break
      case 'S7': // FS-PM 초회보험료 조회 202009 외화보험
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS18S10'))
        pParams.data = {}
        // pParams.contNo = this.contNo
        pParams.data.contNo = this.contNo
        break
      default:
        break
    }

    
    pParams.srnId = this.$options.screenId // 대표 화면 명
    PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)

  },

  /************************************************************************************************
   * Function명  : fn_ServiceResultHandler
   * 설명        : 서비스 조회 결과 함수
   ************************************************************************************************/
  async fn_ServiceResultHandler (event, serviceName) {
    let lv_data = event.data
    if (!lv_data) {
      this.fn_Trace('error', '[접수테스트]' + serviceName + ':: Data 값 오류')
    } // end if
    switch (serviceName) {
      case 'S1': // 전자서명 세부 정보조회
        this.eltrnSaveData = lv_data
        if (this.eltrnSaveData.corpNomnPinsYn === 'Y') {
          // 계약자의 BPID를 예금주의 BPID로
          this.eltrnSaveData.dpstrNm = this.eltrnSaveData.mnContrNm
          this.eltrnSaveData.elstDpstrPartnNo = this.eltrnSaveData.elstPartnNo
        }
        this.contPrmAmt = lv_data.mpftp // 초회보험료 이체
        this.crncyCd = this.eltrnSaveData.crncyCd // 202005 외화보험 통화키
        this.contNo = this.eltrnSaveData.contNo // 202005 외화보험 계약번호
        // 접수 전 조합한 Data 확인
        this.fn_Trace('info', '[접수테스트]전자서명 상세 조회 완료', this.itemData)

        if (this.crncyCd === 'USD' && !this.$bizUtil.isEmpty(this.contNo) ) {
          this.fn_ServiceData('S6')
        } else {
          this.fn_ServiceData('aeus') // aeus 입력 정보 확인
        }
        break
      case 'S1_1': // 전자서명 세부 정보조회
        this.eltrnSaveData = lv_data
        this.crncyCd = this.eltrnSaveData.crncyCd // 202005 외화보험 통화키
        this.contNo = this.eltrnSaveData.contNo // 202005 외화보험 계약번호
        if (this.eltrnSaveData.corpNomnPinsYn === 'Y') {
          // 계약자의 BPID를 예금주의 BPID로
          this.eltrnSaveData.dpstrNm = this.eltrnSaveData.mnContrNm
          this.eltrnSaveData.elstDpstrPartnNo = this.eltrnSaveData.elstPartnNo
        }
        this.fn_Trace('info', '[접수테스트]접수 세부정보 입력 후 상세 조회 완료')
        this.fn_ServiceData('aeus_1') // aeus 입력 정보 확인
        break
      case 'U2':
        if (lv_data.trtRslt === 1) {
          this.fn_ScnAcpnPsbDetail(serviceName) // 접수 상세 체크 팝업 확인
        } // end if
        break
      case 'S3': // 수익자 인증 기록 조회 - TTSD00003 Table
        this.fn_Trace('info', '[접수테스트] 수익자 인증 기록 조회 완료')
        this.fn_CusBnfrInfoData(lv_data['pSBnfrAthntDVO']) // 수익자 정보 가공
        break
      case 'U4': // 서비스 콜 입력
        if (lv_data.trtRslt === 1) {
          this.fn_Trace('info', '[접수테스트] 서비스 콜 입력 완료')
          if (this.eltrnSaveData.corpNomnPinsYn === 'N') {
            // 법인이 아닐떄
            this.fn_ServiceData('U5_1') // 계약자 기준정보 업데이트
          } else {
            // 법인 일때
            if (this.itemData.signerData['contrInsrdSameYn'] === 'N') { // 계피상이 일때
              if ((this.itemData.signerData.mnisdMiorYn === 'Y' || this.itemData.signerData.mnisdMiorYn === 'X') ||
                  this.itemData.signerData.unchYn === 'Y') { // 미성년자(태아) 여부 (조회는 'Y' 입력/수정 'X')) {                        
                this.fn_ServiceData('P5') // 청약입력/접수
              } else {
                this.fn_ServiceData('U5_2') // 피보험자 기준정보 업데이트
              } // end else if
            } else {              
              this.fn_ServiceData('P5') // 청약입력/접수
            } // end else if
          }
        } // end if
        break
      case 'U5_1': // 계약자 기준정보 업데이트
        if (lv_data.trtRslt === 1) {
          this.fn_Trace('info', '[접수테스트] 계약자 기준정보 업데이트 완료')
          if (this.itemData.signerData['contrInsrdSameYn'] === 'N') { // 계피상이 일때
            if ((this.itemData.signerData.mnisdMiorYn === 'Y' || this.itemData.signerData.mnisdMiorYn === 'X') ||
                this.itemData.signerData.unchYn === 'Y') { // 미성년자(태아) 여부 (조회는 'Y' 입력/수정 'X')) {              
              this.fn_ServiceData('P5') // 청약입력/접수
            } else {
              this.fn_ServiceData('U5_2') // 피보험자 기준정보 업데이트
            } // end else if
          } else {
            this.fn_ServiceData('P5') // 청약입력/접수
          } // end else if
        } else {
          this.fn_Trace('info', '[접수테스트] 계약자 기준정보 업데이트 오류' + lv_data.trtMsg)
          this.getStore('confirm').dispatch('ADD', '기준정보(계약자) 업데이트 중 오류가 발생했습니다.')
        } // end else if
        break
      case 'U5_2': // 피보험자 기준정보 업데이트
        if (lv_data.trtRslt === 1) {
          this.fn_Trace('info', '[접수테스트] 피보험자 기준정보 업데이트 완료')
          if (this.eltrnSaveData.corpNomnPinsYn === 'N') {            
            // 법인이 아닐때
            this.fn_ServiceData('P5') // 청약입력/접수
          } else {
            // 법인 일때
            this.fn_ServiceData('U5_3') // 법인 실제소유자 정보 등록
          }
        } else {
          this.fn_Trace('info', '[접수테스트] 피보험자 기준정보 업데이트 오류' + lv_data.trtMsg)
          this.getStore('confirm').dispatch('ADD', '기준정보(피보험자) 업데이트 중 오류가 발생했습니다.')
        } // end else if
        break
      case 'U5_3': // 법인 실제소유자 정보 등록
        if (lv_data.partner) {
          this.fn_ServiceData('P5') // 청약입력/접수
        } else {
          this.fn_Trace('info', '[접수테스트] 법인 실제소유자 정보 등록 오류')
          this.getStore('confirm').dispatch('ADD', '법인 실제소유자 정보 등록 중 오류가 발생했습니다.')
        }
        break
      case 'P5': // 청약입력/접수
        if (lv_data.zBacaMasterDto && lv_data.zBacaMasterDto.length > 0 ) {
          let zBacaMasterDto = lv_data.zBacaMasterDto[0]
          if (zBacaMasterDto.nrmTrtYn === 'Y') {
            this.eltrnSaveData.contNo = zBacaMasterDto.applnrTt // 접수번호(계약번호)
            this.contNo = this.eltrnSaveData.contNo // 202009 계약번호
            // this.eltrnSaveData.mpftp = zBacaMasterDto.bacaApifPolicy[0].zzRlpadPrmAm.intAmount // 실납입보험료
            // 202008 외화보험 초회보험료
            this.eltrnSaveData.ofrAcpnrEno = lv_data.userId // 청약접수자사번

            if (!this.$bizUtil.isEmpty(this.crncyCd) && this.crncyCd === 'USD') {
              this.eltrnSaveData.mpftp = zBacaMasterDto.bacaApifPolicy[0].zzRlpadPrmAm.amount // 실납입보험료
              this.contPrmAmt = this.eltrnSaveData.mpftp
              // this.contPrmAmtKrw = this.$bizUtil.getRoundUp(Number(this.contPrmAmt) * Number(this.exrtRate), 0)
              this.fn_ServiceData('S7') // 외화보험 PM내 Cash 원화금액 조회
            } else {
              this.eltrnSaveData.mpftp = zBacaMasterDto.bacaApifPolicy[0].zzRlpadPrmAm.intAmount // 실납입보험료
              this.contPrmAmt = this.eltrnSaveData.mpftp
              this.fn_Trace('info', '[접수테스트] 청약입력/접수 완료')
              this.fn_ServiceData('U6') // 청약입력/접수 결과저장
            }            
          } else if (zBacaMasterDto.nrmTrtYn === 'T') {
            this.fn_Trace('info', '[접수테스트] 청약입력/접수 불가 시간')
            this.getStore('confirm').dispatch('ADD', zBacaMasterDto.untyMsgCntnt)
          } else {
            let bacaSysUndw = zBacaMasterDto.bacaSysUndw
            if (zBacaMasterDto.applnrTt && String(zBacaMasterDto.applnrTt).substring(0, 4) === '0004') {
              this.eltrnSaveData.contNo = zBacaMasterDto.applnrTt // 접수번호(계약번호)
              this.eltrnSaveData.mpftp = zBacaMasterDto.bacaApifPolicy[0].zzRlpadPrmAm.intAmount // 실납입보험료
              this.eltrnSaveData.ofrAcpnrEno = lv_data.userId // 청약접수자사번
              this.untyMsgCntnt = zBacaMasterDto.untyMsgCntnt // 메세지 추가
              this.fn_Trace('info', '[접수테스트] 청약입력/접수 이미 처리')
              this.fn_ServiceData('U6') // 청약입력/접수 결과저장
            } else {
              let bacaSys = ''
              if (bacaSysUndw) {
                bacaSysUndw.forEach(element => {
                  bacaSys += '\n[' + element.zaUndwRsltMsgCntnt + ']'
                })
              } // end if
              this.fn_Trace('error', '[접수테스트] 청약입력/접수 오류 발생')
              this.getStore('confirm').dispatch('ADD', zBacaMasterDto.untyMsgCntnt + bacaSys)
              // '청약접수가 실패하였습니다.<br>이후 지점을 통해서도 진행이 가능합니다.'              
            } // end if
          } // end else if
        } else {
          this.fn_Trace('error', '[접수테스트] 청약입력/접수 오류 발생')
        } // end else if
        break
      case 'U6': // 청약입력/접수 결과 저장
        if (lv_data.trtRslt === 1) {
          this.fn_Trace('info', '[접수테스트] 청약입력/접수 결과 저장 완료')
          this.fn_ServiceData('U7') // 청약입력/접수
        } // end if
        break
      case 'U7': // 현장접수완료일자수정
        if (lv_data.trtRslt === 1) {
          this.fn_Trace('info', '[접수테스트] 현장접수완료일자수정 완료')
          this.fn_ServiceData('P15') // 이미지 스캔 완료
          //전자서명 완료시 사용자 이벤트 로그 기록 처리 30:접수완료
          PSCommUtil.prcsEvtLog(this,'MSPPS611D','접수완료',this.itemData['vuchId'])
        } // end if
        break
      case 'U8': // 초회보험료이체일자수정
        if (lv_data.trtRslt === 1) {
          this.fn_Trace('info', '[접수테스트]  초회보험료이체일자수정')
          if ((this.addContBankInfo.payid && this.addContBankInfo.payid !== ' ') || this.eltrnSaveData.frtmPrmPymId !== ' ') {
            this.fn_Trace('info', '[접수테스트]  RTP 처리 이미 완료한 상태')
            // this.step = '3' // 보험료 이체 확인
            this.fn_ServiceData('P11') // RTP 처리
          } else {
            if(this.eltrnSaveData.frtmPrmPadMthCd === '9'){
              this.fn_ServiceData('P11') // RTP처리 - 카드납일때 출금동의요청 생략
            }else{
              this.fn_ServiceData('P9') // 이체 시작
            }
          } // end else if
        } // end if
        break
      case 'U8_dis': // 이체불가 처리 일때
      case 'U8_end': // 가상계좌 와 후입금 일때 이체 불가 또는 처리된 접수 일 때 99999999 입력처리 결과
        if (lv_data.trtRslt === 1) {
          this.fn_Trace('info', '[접수테스트] 이체 불가 처리 이체일자수정 99999999')
          if (this.untyMsgCntnt !== ' ') {
            this.getStore('confirm').dispatch('ADD', this.untyMsgCntnt)
          } // end if
          if (serviceName === 'U8_end') {
            this.fn_ComponentChange('6') // 청약 접수 신청 완료 화면
          } // end if
        } // end if
        break
      case 'P9': // 출금동의 요청
        if (lv_data.zaPayrNo !== '' && lv_data.zaPayrNo !== ' ') {
          this.fn_Trace('info', '[접수테스트]  출금동의 요청 완료')
          this.fn_ServiceData('S10') // 출금동의 등록 조회
        } else {          
          this.getStore('confirm').dispatch('ADD', '출금동의 등록을 할 수 없습니다.\n계좌 오류이거나 해당 계좌가 기업은행 평생계좌(휴대폰번호 체계)인 경우, 실시간 출금이체가 불가능합니다.\n임직원(지점프로 등)을 통해 일반 계좌 등록 후, 출금을 다시 진행해 주시기 바랍니다. ')
        }// end else if
        break
      case 'S10': // 출금동의 등록 조회
        if (lv_data.zaRegYn === 'Y') {
          this.fn_Trace('info', '[접수테스트]  출금동의 등록 조회 완료')
          this.fn_ServiceData('P11') // RTP 처리
        } else {
          this.untyMsgCntnt = '입금처리 실패, 익일 지점 통해 재처리 바랍니다.'
          this.fn_ServiceData('U8_dis') // 입금처리 실패
        } // end else if
        break
      case 'P11': // RTP 처리
        if (lv_data.result === 'S') { // 처리중' 및 '정상응답' 시 'S'/ 불능응답' 시 'E'
          this.addContBankInfo.payid = lv_data.payid
          this.fn_Trace('info', '[접수테스트]  RTP 처리 완료')
          this.fn_ServiceData('U12') // 초회보험료지급ID업데이트
        } else if (lv_data.message === 'E') {
          this.fn_Trace('info', '[접수테스트]  RTP 처리 실패', lv_data.msg)
          if (lv_data.zaTcompResp_cd === '0608') {
            this.getStore('confirm').dispatch('ADD', '잔고 부족입니다. 잔고 확인 후 재처리 바랍니다.')
          } else {
            // 2023.05.23 ASR230401054_(사랑On_청약심사) 현장출금 RTP 처리 시 메세지 정교화
            // this.getStore('confirm').dispatch('ADD', '계좌상태 확인 후 지점에서 출금 바랍니다.')
            this.getStore('confirm').dispatch('ADD', '잔고부족 또는 계좌상태 확인 후 지점에서 출금 바랍니다.')
          }
        } // end else if
        break
      case 'U12': // 초회보험료지급ID업데이트
        if (lv_data.trtRslt === 1) {
          this.fn_Trace('info', '[접수테스트] 초회보험료지급ID업데이트 완료')
          this.step = '3' // 이체확인 화면
          this.fn_TiltChange(this.step)
        } // end if
        break
      case 'S13': // RTP 처리 결과 조회

        if(this.eltrnSaveData.frtmPrmPadMthCd === '9'){
          if (lv_data.result === 'S' && lv_data.strtp === '22') {
            this.fn_Trace('info', '[RTP 처리 결과]  RTP 처리 결과 조회완료')
            this.fn_ServiceData('U14') // 초회보험료이체완료
          }else{
            this.fn_Trace('error', '[RTP 처리 결과] RTP 처리 결과 조회 실패', lv_data)
            this.getStore('confirm').dispatch('ADD', '보험료 입금 확인 중\n(지점을 통해 확인하세요.)')
          } 
        }else{
          if (lv_data.result === 'S' && lv_data.strtp === '15') {
            this.fn_Trace('info', '[RTP 처리 결과]  RTP 처리 결과 조회완료')
            this.fn_ServiceData('U14') // 초회보험료이체완료
          }else{
            this.fn_Trace('error', '[RTP 처리 결과] RTP 처리 결과 조회 실패', lv_data)
            this.getStore('confirm').dispatch('ADD', '보험료 입금 확인 중\n(지점을 통해 확인하세요.)')
          }
        }

        break
      case 'U14': // 초회보험료이체완료
        if (lv_data.trtRslt === 1) {
          this.fn_Trace('info', '[접수테스트] 초회보험료이체완료')
          this.step = '4' // 신계약체결 완료
          this.fn_TiltChange(this.step)

          //전자서명 완료시 사용자 이벤트 로그 기록 처리 40:이체완료          
          PSCommUtil.prcsEvtLog(this,'MSPPS611D','이체완료',this.itemData['vuchId'])

        } // end if
        break
      case 'P15': // 이미지스캔
        this.fn_Trace('info', '[접수테스트] 이미지스캔 호출 완료')
        // 가상계좌 와 후입금 일때 이체 불가 또는 처리된 접수 일 때
        // 2023.02.21 ASR221200877_(사랑ON청약심사) 신계약 간편납입(카카오PAY) 개발 요청
        if (this.eltrnSaveData.frtmPrmPadMthCd === '4' || this.eltrnSaveData.aftRctmHopYn === 'Y' || this.untyMsgCntnt !== ' ' || this.eltrnSaveData.frtmPrmPadMthCd === 'F') {
          this.fn_ServiceData('U8_end') // 초회보험료이체일자수정
        } else {
          this.contPrmAmt = this.eltrnSaveData.mpftp // 초회보험료 이체
          this.step = '2' // 보험료 이체 시작
          this.fn_TiltChange(this.step)
        } // end else if
        break
      case 'aeus': // AEUS 대상 여부 조회
      case 'aeus_1': // AEUS 대상 여부 조회
        let newDateEus = PSDateUtil.fn_CurrentDate()
        if (lv_data) {
          if (lv_data.ZA_AEUS_FAIL === 'Y') { // AEUS 장애여부가 Y인경우에는 AEUS입력체크 기능은 통과되도록 처리(선심사PJT - 2024.03.21) (담당: 오유민프로) 
            this.eltrnSaveData.eusUndwCmpltYmd = '00000000'
          } else {
            if (lv_data.ZA_EUS_PRG_STAT_CD === '0') { // 입력 필요
              if (lv_data.ZA_AEUS_REQUIRED === 'N') { // AEUS 필수여부가 N인경우에는 AEUS입력체크 기능은 통과되도록 처리(선심사PJT - 2024.03.21) (담당: 오유민프로) 
                this.eltrnSaveData.eusUndwCmpltYmd = '00000000'
              } else {
                this.fn_Trace('info', '[접수테스트] AEUS 입력 필 요합니다.')
                this.eltrnSaveData.eusUndwCmpltYmd = ' '
              }
            } else if (lv_data.ZA_EUS_PRG_STAT_CD === '1') { // 입력 완료
              this.eltrnSaveData.eusUndwCmpltYmd = newDateEus
            } else { // 비대상
              this.eltrnSaveData.eusUndwCmpltYmd = '00000000'
            } // end else if
          }
        } else {
          this.eltrnSaveData.eusUndwCmpltYmd = newDateEus
          this.fn_Trace('error', '[접수테스트] AEUS 접속 오류 발생.')
        } // end if
        if (serviceName === 'aeus_1') {
          this.fn_ScnAcpnPsbDetail(serviceName) // 접수 상세 체크 팝업 확인
        } // end if
        break
      case 'P16': // 지급전문 2종 호출
        // 서비스 호출만 하기때문에 주석처리 추후 동기로 할때 해제 필요
        /*
        let zCrdinInfoMobIfDto = lv_data.zcrdinInfoMobIfDto
        if (zCrdinInfoMobIfDto.zaNrmYn === 'Y') {
          // this.fn_ServiceData('U2') // 현장접수신청일자
        } else {
          // this.getStore('confirm').dispatch('ADD', zCrdinInfoMobIfDto.zaUntyMsgCntnt)
        } // end else if
        */
        break
      case 'S4': // 2020.06.23 ASR200501008_전자서명 완료시간 확대 (계좌검증 추가)
        if (lv_data.za_RFND_YN === 'Y') {
          let accnVerfMsg = (this.serverChoice !== 'production')
          if (accnVerfMsg) {
            this.getStore('confirm').dispatch('ADD', '[검증계] 계좌선택시간 23:30~07:30 -> 계좌검증완료')
          }
          this.fn_ServiceData('U2')
        } else {
          let t_This = this
          this.$commonUtil.getServerTime().then(function (response) {
            let currTm = moment(response).format('HHmmss')
            // 2020.07.21 ASR200700506 전자서명 현장접수시간 확대 ( 20시 → 23시)
            if (Number(currTm) >= 83000 && Number(currTm) <= 230000) {
              t_This.getStore('confirm').dispatch('ADD', '계좌정보 오류입니다.\n① 전자서명 재작성을 통한 현장접수 진행, 또는\n② 출금동의서에 올바른 계좌번호 작성 후 지점접수 진행바랍니다.')
            } else {
              t_This.getStore('confirm').dispatch('ADD', '현장접수 가능 시간이 아닙니다.')
            }
          })
        }
        break
      case 'S5': // 202008 외화보험 FS-PM 환율조회후 외화보험 금액 = USD x 계약일환율의 반올림 정수
        if (this.$bizUtil.isEmpty(lv_data.exrtRate)) {
          this.getStore('confirm').dispatch('ADD', '조회된 환율이 없습니다.')
        } else {
          this.exrtRate = lv_data.exrtRate
          this.contPrmAmtKrw = this.$bizUtil.getRoundUp(Number(this.contPrmAmt) * Number(this.exrtRate), 0)
          this.fn_ServiceData('aeus') // aeus 입력 정보 확인
        }
        break
      case 'S6': // 202009 외화보험 PM내 Cash 원화금액 조회
        if (this.$bizUtil.isEmpty(lv_data.amountAmStr)) {
          this.getStore('confirm').dispatch('ADD', '조회된 외화보험 원화금액이 없습니다.')
        } else {
          this.contPrmAmtKrw = lv_data.amountAmStr
          this.fn_ServiceData('aeus') // aeus 입력 정보 확인
        }
        break
      case 'S7': // 202009 외화보험 PM내 Cash 원화금액 조회 청약입력단계
        if (this.$bizUtil.isEmpty(lv_data.amountAmStr)) {
          this.getStore('confirm').dispatch('ADD', '조회된 외화보험 원화금액이 없습니다.')
        } else {
          this.contPrmAmtKrw = lv_data.amountAmStr
          this.fn_ServiceData('U6') // 청약입력/접수 결과저장
        }
        break
      default:
        break
    }
  },

  /************************************************************************************************
   * Function명  : fn_ServiceFaultHandler
   * 설명        : 서비스 조회 에러 함수
   ************************************************************************************************/
  
  fn_ServiceFaultHandler (event, serviceName) {
    this.fn_Trace('error', serviceName + '::' + event.msgDesc)
    switch (serviceName) {
      case 'S1': // 전자서명 세부 정보조회
      case 'S1_1': // 전자서명 세부 정보조회
      case 'U2': // 현장접수신청일자
      case 'S3': // 수익자 인증 기록 조회 - TTSD00003 Table
      case 'U4': // 서비스 콜 입력
      case 'U5_1': // 계약자 기준정보 업데이트
      case 'U5_2': // 피보험자 기준정보 업데이트
      case 'U5_3': // 법인 실제소유자 정보 등록      
      case 'P5': // 청약입력/접수
      case 'U6': // 청약입력/접수 결과 저장
      case 'U7': // 현장접수완료일자수정
      case 'U12': // 초회보험료지급ID업데이트
      case 'S13': // RTP 처리 결과 조회
      case 'U14': // 초회보험료이체완료
        this.fn_Trace('error', serviceName + '::' + event.msgDesc)
        // '청약접수가 실패하였습니다.<br>이후 지점을 통해서도 진행이 가능합니다.'
        this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC020'].replace('<br>', '\n'))
        break
      case 'U8': // 초회보험료이체일자수정
      case 'U8_dis': // 초회보험료이체 실패
      case 'U8_end': // 가상계좌 와 후입금 일때 이체 불가 또는 처리된 접수 일 때 99999999 입력처리 결과
      case 'P9': // 출금동의 요청
      case 'S10': // 출금동의 등록 조회
        this.untyMsgCntnt = '입금처리 실패, 익일 지점 통해 재처리 바랍니다.'
        this.fn_ServiceData('U8_dis') // 입금처리 실패
        break
      case 'P11': // RTP 처리
        // '초회보험료 이체가 실패하였습니다.<br>지점으로 초회보험료 이체를 요청하세요.'
        this.getStore('confirm').dispatch('ADD', '보험료 입금 확인 중. 잔고 확인 후 재처리 바랍니다.')
        break
      case 'P15': // 이미지스캔
        this.fn_Trace('error', '[접수테스트] 이미지스캔 오류.')
          // 가상계좌 와 후입금 일때 이체 불가 또는 처리된 접수 일 때
          // 2023.02.21 ASR221200877_(사랑ON청약심사) 신계약 간편납입(카카오PAY) 개발 요청
          if (this.eltrnSaveData.frtmPrmPadMthCd === '4' || this.eltrnSaveData.aftRctmHopYn === 'Y' || this.untyMsgCntnt !== ' ' || this.eltrnSaveData.frtmPrmPadMthCd === 'F') {
            this.fn_ServiceData('U8_end') // 초회보험료이체일자수정
          } else {
            this.contPrmAmt = this.eltrnSaveData.mpftp // 초회보험료 이체
            this.step = '2' // 보험료 이체 시작
          } // end else if
          break
      case 'aeus': // AEUS 대상 여부 조회
      case 'aeus_1': // AEUS 대상 여부 조회
        let newDateEus = PSDateUtil.fn_CurrentDate()
        this.eltrnSaveData.eusUndwCmpltYmd = newDateEus
        this.fn_Trace('error', '[접수테스트] AEUS 접속 오류 발생.')
        if (serviceName === 'aeus_1') {
          this.fn_ScnAcpnPsbDetail(serviceName) // 접수 상세 체크 팝업 확인
        } // end if
        break
      case 'P16': // 지급전문 2종 호출
        this.fn_Trace('error', serviceName + '::' + event.msgDesc)
        break
      default:
        this.fn_Trace('error', serviceName + '::' + event.msgDesc)
        this.getStore('confirm').dispatch('ADD', event.msgDesc)
        break
    }
  },

  
  /*---------------------------------------------------------------------------------
  * 기타 Util 영역
  ---------------------------------------------------------------------------------*/

  /******************************************************************************
  * Function명 : fn_ScnAcpnPsbDetail
  * 설명       : 접수 상세 팝업 호출 여부
  ******************************************************************************/
  fn_ScnAcpnPsbDetail (type) {
    let idcTofCnfObjYn = this.eltrnSaveData.idcTofCnfObjYn // 신분증진위확인대상여부)
    let idcTofCnfDtm = this.eltrnSaveData.idcTofCnfDtm // 신분증진위확인대상여부
    let prtrPesonGudnObjYn = this.eltrnSaveData.prtrPesonGudnObjYn // 친권자안내대상여부
    let prtrPesonNm01 = this.eltrnSaveData.prtrPesonNm01 // 친권자안내대상여부    
    let prtrPesonTelnoEncr01 = this.eltrnSaveData.prtrPesonTelnoEncr01 //친권인 연락처 사전정보입력 정보 자동반영(현장접수팝업삭제)    
    let bnfrDfrncYn = this.eltrnSaveData.bnfrDfrncYn // 수익자상이여부

    // =====================================================
    let bnfrCnsntCnfCd = this.eltrnSaveData.bnfrCnsntCnfCd // 수익자동의확인코드
    let frtmPrmPadMthCd = this.eltrnSaveData.frtmPrmPadMthCd // 계좌 유형
    let bnkAccnInfoId = this.eltrnSaveData.bnkAccnInfoId // 계좌 생성 확인
    let eusUndwCmpltYmd = this.eltrnSaveData.eusUndwCmpltYmd // EUS계약심사완료일자
    // =====================================================
    
    let asgnProxyClamEntYn = this.eltrnSaveData.asgnProxyClamEntYn // 지정대리청구 동의대상 여부
    let apcCnsntYn = this.eltrnSaveData.apcCnsntYn // 지정대리청구 동의여부
    let apcPrsonAplScCd = this.eltrnSaveData.apcPrsonAplScCd // 지대청 2인지정 현장접수 반영
    let apcCnsntYn02 = this.eltrnSaveData.apcCnsntYn02 // 지대청 2인지정 현장접수 반영

    let simpAsgnClamAgntCd01 = this.eltrnSaveData.simpAsgnClamAgntCd01 // 1인 간편지대청 여부
    let simpAsgnClamAgntCd02 = this.eltrnSaveData.simpAsgnClamAgntCd02 // 2인 간편지대청 여부

    // 지정대리청구인
    // 전자서명 청약접수 상세 입력 화면 여부

    if ((idcTofCnfObjYn === 'Y' && idcTofCnfDtm === ' ') ||
      (prtrPesonGudnObjYn === 'Y' && (prtrPesonNm01 === ' ' || prtrPesonTelnoEncr01 === ' ')) ||
      (bnfrDfrncYn === 'Y' && bnfrCnsntCnfCd !== 'Y') ||
      (frtmPrmPadMthCd === '4' && bnkAccnInfoId === ' ') || eusUndwCmpltYmd === ' ' ||
      (simpAsgnClamAgntCd01 !== 'B' && asgnProxyClamEntYn === 'Y' && apcCnsntYn !== 'Y') ||
      (simpAsgnClamAgntCd02 !== 'B' && apcPrsonAplScCd === '02' && apcCnsntYn02 !== 'Y') ) { // 지대청 2인지정 현장접수 반영
      if (type === 'U2') {
        this.fn_Trace('info', '[접수테스트]접수 세부정보 팝업 호출')
        this.itemData611P = {}
        this.itemData611P.bnfrInfoList = {}
        this.itemData611P.gafData = this.itemData.gafData || {} // 계약자/피보험자/수익자/상품정보
        if (bnfrDfrncYn === 'Y' && bnfrCnsntCnfCd !== 'Y') { // 수익자 상이 여부가 맞다면
          this.fn_ServiceData('S3') // 수익자 인증 기록 조회
        } else {
          this.itemData611P.eltrnSaveData = this.eltrnSaveData
          this.itemData611P.bnfrInfoList = []
          this.fn_OpenMspps611p()
        }
      } else {
        this.fn_Trace('error', '[접수테스트]접수 세부정보 입력 불충분')
      } // end else if
    } else {
      this.fn_Trace('info', '[접수테스트] 청약 접수 시작')
      this.fn_ServiceData('U4') // 청약 접수 시작
    } // end else if
  },

  /******************************************************************************
  * Function명 : fn_CusBnfrInfoData
  * 설명       : 수익자 데이터 가공 함수
  ******************************************************************************/
 
  fn_CusBnfrInfoData (val) {
    let d = this.itemData.gafData
    let hl = d.hoshInfoList || []
    let bl = d.bnfrInfoList || []
    let rrn1 = '' // 계약자 주민
    let rrn2 = '' // 주피보험자 주민
    let rrn3 = '' // 종피보험자 주민
    let tmpArr = []
    let resData = val || []

    for (let i = 0; i < hl.length; i++) {
      if (hl[i].contvInsrdCd === '11') rrn1 = hl[i].custRrnId
      else if (hl[i].contvInsrdCd === '21') rrn2 = hl[i].custRrnId
      else if (hl[i].contvInsrdCd === '23') rrn3 = hl[i].custRrnId
    }

    for (let i = 0; i < bl.length; i++) {
      if (bl[i].custRrnId !== '' && (bl[i].custRrnId === rrn1 || bl[i].custRrnId === rrn2)) { // 수익자 주민번호가 계약자 or 피보험자와 같을때
        continue
      } else if (bl[i].contvInsrdCd === '23' && bl[i].bnfrRolCd === '34' && bl[i].custRrnId === rrn3) { // 종피상해시수익자, 기본값(종피보험자)
        continue
      } else if (bl[i].contvInsrdCd === '23' && bl[i].bnfrRolCd === '35' && bl[i].custNm === '상속인') { // 종피사망시수익자, 기본값(상속인)
        continue
      } else if (bl[i].contvInsrdCd === '21' && bl[i].bnfrRolCd === '34' && bl[i].custRrnId === rrn2) { // 주피상해수익자, 기본값(피보험자)
        continue
      } else if (bl[i].contvInsrdCd === '21' && bl[i].bnfrRolCd === '35' && bl[i].bnfrSno === 1 && bl[i].custNm === '상속인') { // 주피사망수익자1, 기본값(상속인)
        continue
      } else if (bl[i].contvInsrdCd === '21' && bl[i].bnfrRolCd === '35' && bl[i].bnfrSno === 2 && (bl[i].custNm === '' || bl[i].custNm === '상속인')) { // 주피사망수익자2, 기본값(빈칸)
        continue
      } else if (bl[i].contvInsrdCd === '21' && bl[i].bnfrRolCd === '35' && bl[i].bnfrSno === 3 && (bl[i].custNm === '' || bl[i].custNm === '상속인')) { // 주피사망수익자3, 기본값(빈칸)
        continue
      } else if (bl[i].contvInsrdCd === '21' && bl[i].bnfrRolCd === '35' && bl[i].bnfrSno === 4 && (bl[i].custNm === '' || bl[i].custNm === '상속인')) { // 주피사망수익자4, 기본값(빈칸)
        continue
      } else if (bl[i].contvInsrdCd === '21' && (bl[i].bnfrRolCd === '31' || bl[i].bnfrRolCd === '32' || bl[i].bnfrRolCd === '33') && bl[i].custRrnId === rrn1) { // 주피만기/분할/연금수익자, 기본값(계약자)
        continue
      } else if (bl[i].contvInsrdCd === '21' && bl[i].bnfrRolCd === '39' && bl[i].custNm === '상속인') { // 주피연금후수익자, 기본값(상속인)
        continue
      } else {
        for (let j = 0; j < resData.length; j++) {
          if (resData[j].vuchId === this.eltrnSaveData.vuchId 
              && resData[j].bnfrRolCd === bl[i].bnfrRolCd 
              && resData[j].elstPartnNo === bl[i].partnerId 
              && resData[j].contvInsrdTypCd === bl[i].contvInsrdCd) {

            if (resData[j].bnfrSno === '0') {
              bl[i].certified = true // 인증 받았는지 여부
              break
            } else {
              if (resData[j].bnfrSno === String(bl[i].bnfrSno)) {
                bl[i].certified = true // 인증 받았는지 여부
                break
              } else {
                bl[i].certified = false // 인증 받았는지 여부
              }
            }
          } else {
            bl[i].certified = false
          }
        }
        tmpArr.push(bl[i])
      }
    }
    this.itemData611P.bnfrInfoList = tmpArr // 수익자동의 데이터
    if (tmpArr.length < 1) {
      this.fn_UpdBnfrCnsntCnfCd() // 수익자동의완료여부 업데이트
    } else {
      this.itemData611P.eltrnSaveData = this.eltrnSaveData
      this.fn_OpenMspps611p()
    }
  },

  /******************************************************************************
  * Function명 : fn_UpdBnfrCnsntCnfCd
  * 설명       : 수익자동의완료여부 업데이트
  ******************************************************************************/
  fn_UpdBnfrCnsntCnfCd () {
    let pParams = {}
    pParams.data = {}
    pParams.srnId = this.$options.screenId // 대표 화면 명
    pParams.appId = 'tss.ps'
    pParams.fnctScCd = 'U'
    pParams.trnstId = 'txTSSPS99U8'
    pParams.data.idcTofYn = this.eltrnSaveData.idcTofYn // 신분증진위여부
    pParams.data.idcTofCnfDtm = this.eltrnSaveData.idcTofCnfDtm // 신분증진위확인일시
    pParams.data.prtrPesonNm01 = this.eltrnSaveData.prtrPesonNm01 // 친권자명01
    pParams.data.prtrPesonTelnoEncr01 = this.eltrnSaveData.prtrPesonTelnoEncr01 // 친권자전화번호암호화01
    pParams.data.prtrPesonNm02 = this.eltrnSaveData.prtrPesonNm02 // 친권자명02
    pParams.data.prtrPesonTelnoEncr02 = this.eltrnSaveData.prtrPesonTelnoEncr02 // 친권자전화번호암호화02
    pParams.data.bnkAccnInfoId = this.eltrnSaveData.bnkAccnInfoId // 가상계좌ID
    pParams.data.bnfrCnsntCnfCd = this.eltrnSaveData.bnfrCnsntCnfCd = 'Y' // 수익자동의확인코드
    pParams.data.vuchId = this.eltrnSaveData.vuchId // 영수증id
    pParams.data.apcCnsntYn = this.eltrnSaveData.apcCnsntYn // 지정대리청구인동의확인코드
    pParams.data.apcCnsntYn02 = this.eltrnSaveData.apcCnsntYn02 // 2021.06.22 ASR210501099_지대청 2인지정 현장접수 반영
    PSServiceManager.invoke(pParams, this.fn_UpdBnfrCnsntCnfCdResult, '', this.fn_UpdBnfrCnsntCnfCdFault)
  },

  /******************************************************************************
  * Function명 : fn_UpdBnfrCnsntCnfCdResult
  * 설명       : 수익자동의완료여부 업데이트 Result
  ******************************************************************************/
  fn_UpdBnfrCnsntCnfCdResult (event, serviceName) {
    let d = event.data
    if (d && d.trtRslt && d.trtRslt > 0) {
    } else {
      this.getStore('confirm').dispatch('ADD', event.msgComm.msgCd + ' ' + event.msgComm.msgDesc)
    }
    // 수익자동의완료여부 업데이트 후 또 조건 체크...
    let idcTofCnfObjYn = this.eltrnSaveData.idcTofCnfObjYn // 신분증진위확인대상여부)
    let idcTofCnfDtm = this.eltrnSaveData.idcTofCnfDtm // 신분증진위확인대상여부
    let prtrPesonGudnObjYn = this.eltrnSaveData.prtrPesonGudnObjYn // 친권자안내대상여부
    let prtrPesonNm01 = this.eltrnSaveData.prtrPesonNm01 // 친권자안내대상여부
    // 2022.02.08 ASR220100585_친권인 연락처 사전정보입력 정보 자동반영(현장접수팝업삭제)
    let prtrPesonTelnoEncr01 = this.eltrnSaveData.prtrPesonTelnoEncr01
    let bnfrDfrncYn = this.eltrnSaveData.bnfrDfrncYn // 수익자상이여부
    let bnfrCnsntCnfCd = this.eltrnSaveData.bnfrCnsntCnfCd // 수익자동의확인코드
    let frtmPrmPadMthCd = this.eltrnSaveData.frtmPrmPadMthCd // 계좌 유형
    let bnkAccnInfoId = this.eltrnSaveData.bnkAccnInfoId // 계좌 생성 확인
    let eusUndwCmpltYmd = this.eltrnSaveData.eusUndwCmpltYmd // EUS계약심사완료일자

    // 전자서명 청약접수 상세 입력 화면 여부
    if ((idcTofCnfObjYn === 'Y' && idcTofCnfDtm === ' ') ||
        (prtrPesonGudnObjYn === 'Y' && (prtrPesonNm01 === ' ' || prtrPesonTelnoEncr01 === ' ')) ||
        (bnfrDfrncYn === 'Y' && bnfrCnsntCnfCd !== 'Y') ||
        (frtmPrmPadMthCd === '4' && bnkAccnInfoId === ' ') ||
        eusUndwCmpltYmd === ' ') {
      this.itemData611P.eltrnSaveData = this.eltrnSaveData
      this.fn_OpenMspps611p()
    } else {
      this.fn_Trace('info', '[접수테스트] 청약 접수 시작')
      this.fn_ServiceData('U4') // 청약 접수 시작
    }
  },

  /******************************************************************************
  * Function명 : fn_UpdBnfrCnsntCnfCdFault
  * 설명       : 수익자동의완료여부 업데이트 Fault
  ******************************************************************************/
  fn_UpdBnfrCnsntCnfCdFault (event, serviceName) {
    let msg = event.msgDesc || event.msgComm
    this.getStore('confirm').dispatch('ADD', msg)
  },

  /******************************************************************************
  * Function명 : fn_MovePage
  * 설명       : 설계청약 홈으로 이동
  ******************************************************************************/
  fn_MovePage () {
    this.$emit('childs-movepage-event', this.step)
  },

  /******************************************************************************
  * Function명 : fn_OpenMspps611p
  * 설명       : 청약접수 팝업 open
  ******************************************************************************/
  fn_OpenMspps611p () {//611p
    this.popMSPPS611P = this.$bottomModal.open(MSPPS611P, {
      properties: {
        itemData: this.itemData611P, // 611P에 넘겨줄 데이터
      },
      
      listeners: {
        // 대상팝업 confirmPopup $emit 이벤트명 
        confirmPopup: (rtnData) => {
          this.fn_ServiceData('S1_1') // 전자서명 세부 정보조회
          this.$bottomModal.close(this.popMSPPS611P) // 모달 닫기                        
        },
        // 대상팝업 confirmPopup $emit 이벤트명 
        cancelPopup: (rtnData) => {
          this.fn_ServiceData('S1') // 전자서명 세부 정보조회
          this.$bottomModal.close(this.popMSPPS611P) // 모달 닫기                        
        }
      }
    })
  },

  /******************************************************************************
  * Function명  : fn_BottomAlert
  * 설명        : 확인 팝업
  ******************************************************************************/
  fn_BottomAlert (contents) {
    this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
      properties: {
        single: true,
        title: "알림",
        content: contents,
        title_pos_btn: "확인"
      },
      listeners: {
        onPopupConfirm: () => {
          console.log('onPopupConfirm call')
          this.$bottomModal.close(this.lv_AlertPop)
        },
        onPopupClose: () => {
          console.log('onPopupClose call')
          this.$bottomModal.close(this.lv_AlertPop)
        }
      }
    })
  },

  /******************************************************************************
   * Function명 : fn_ComponentChange
   * 설명 : 화면 전환이벤트
   ******************************************************************************/
  fn_ComponentChange (step) {
    this.$emit('comp-change-event', step)
  },

  /******************************************************************************
   * Function명 : fn_TiltChange
   * 설명 : 타이틀 전환이벤트
   ******************************************************************************/
  fn_TiltChange (step) {
    this.$emit('titl-change-event', step)
  },

  /******************************************************************************
   * Function명 : fn_ActivatedLoading
   * 설명 : 화면 활성화 이후 부모에게 전달 이벤트
   ******************************************************************************/
  fn_ActivatedLoading () {
    this.$emit('childs-activated-evnet', this.name)
  },

  /************************************************************************************************
   * Function명  : fn_Trace
   * 설명        : 프로그램 흐름 및 에러 로그 추적 공통 함수
   ************************************************************************************************/
  fn_Trace (type, addMsg) {
    this.$emit('childs-trace-event', type, addMsg, null, null, this.$options.screenId)
  },

  //--------------------------------------------------------------------------------
    _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의
 },// methods

}//export default


</script>